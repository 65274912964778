.checkout-3d-v2 {
    margin-top: 3%;
    .buttons {
        display: flex;
        justify-content: space-evenly;
        margin: 1rem 0;
    
        .MuiButton-contained {
            box-shadow: none;
            // background-color: #22c1ff;
        }
    
        .large {
            width: 70% !important;
        }
    
        .MuiButton-root {
            color: white;
            font-size: 1rem;
            border-radius: 5px;
    
            &:hover {
                box-shadow: none;
                // background-color: #0171ae;
            }
        }
    }
  }