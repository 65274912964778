
.title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 90%;
    max-width: 450vh;
    font-family: 'Poppins', sans-serif;

    &-image {
        width: 100%;
        width: 3.5rem;
    }

    &-name {
        margin-left: 1.5rem;
        text-transform: uppercase;
        font-size: 20.8px;
        color: #732BFF;
    }
}

.payment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #bebebe;
    padding: 50px 0;
    &-logo {
        width: 45px;
    }

    &-description {
        color: #73848f;
        font-size: 16px;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
    }

    &-modify {
        font-size: 12px;
        font-family: 'Poppins', sans-serif;
        color: #732BFF;
        text-transform: uppercase;
        cursor: pointer;
    }
}
