.container-msg-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .msg-error {
        padding: 5px 30px;
        text-align: center;

        .img-error {
            margin-bottom: 20px;
        }
    }
}