.smallContainer {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: 3px;
    width: 80%;
    max-width: 450vh;
    text-align: center;
    max-height: 250px;
    margin: auto;
}
