@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.card {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;

	.header {
		margin: 10px 10px;
		height: 30px;
	}

	.typography-heading {
		margin: 0;
		font-family: 'Centra Medium';
		font-size: 22px;
		@media screen and (max-width: 1200px) {
			font-size: 17px;
		}
	}

	.typography-amounth {
		margin: 0;
		font-family: 'Centra Medium';
		font-size: 30px;
		color: #5959ea;
		span {
			color: #5959ea;
			font-size: 21px;
		}
		@media screen and (max-width: 1200px) {
			font-size: 24px;
			span {
				font-size: 17px;
			}
		}
	}

	.typography-sub-heading {
		margin: 0;
		font-family: 'Centra Medium';
		font-size: 19px;
		color: #3d3d3d;
		margin-bottom: 16px;
		@media screen and (max-width: 1200px) {
			margin-bottom: 13px;
			font-size: 15px;
		}
	}

	.typography-data {
		margin: 0;
		font-family: 'Centra Book';
		font-size: 17px;
		color: #3d3d3d;
		@media screen and (max-width: 1200px) {
			font-size: 13px;
		}
	}

	.typography {
		margin: 0;
		font-family: 'Centra Light';
		font-size: 14px;
		color: '#606060';
		@media screen and (max-width: 1200px) {
			font-size: 11px;
		}
	}

	.label {
		font-size: 19px;
		margin-bottom: 10px;
		color: #3d3d3d;
		font-family: 'Centra Book';
		@media screen and (max-width: 1200px) {
			font-size: 15px;
		}
	}

	.fields {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		& > div {
			width: 100%;
		}

		.form-input {
			width: 100%;
			height: 37px;
			font-size: 16px;
			outline: none;
			border-radius: 10px;
			border: none;
			font-family: 'Centra Book';
			margin-bottom: 16px;
			color: #666666;
			&::placeholder {
				font-size: 16px;
				font-family: 'Centra Book';
				color: rgba(0, 0, 0, 0.3);
			}
		}
		.custom-form-input {
			width: 98%;
			height: 37px;
			font-size: 16px;
			outline: none;
			border-radius: 10px;
			border: none;
			font-family: 'Centra Book';
			margin-bottom: 8px;
			padding: 0 0 0 10px;
			background-color: '#FFF';
			color: #666666;
			&::placeholder {
				font-size: 16px;
				font-family: 'Centra Book';
				color: rgba(0, 0, 0, 0.35);
			}
		}
	}
	.fields-multi {
		display: flex;
		justify-content: space-between;
		gap: 24px;

		& > div {
			width: 100%;
		}

		.custom-form-input {
			width: 98%;
			height: 33px;
			font-size: 16px;
			outline: none;
			border-radius: 10px;
			border: none;
			font-family: 'Centra Book';
			margin-top: 10px;
			margin-bottom: 16px;
			padding: 0 0 0 10px;
			background-color: '#FFF';
			color: #666666;

			select::-ms-expand {
				display: none;
			}
			&::placeholder {
				font-size: 16px;
				font-family: 'Centra Book';
				color: rgba(0, 0, 0, 0.35);
			}
		}

		.form-input {
			width: 100%;
			height: 33px;
			font-size: 16px;
			outline: none;
			border-radius: 10px;
			border: none;
			font-family: 'Centra Book';
			margin-top: 10px;
			margin-bottom: 16px;
			color: #666666;

			&::placeholder {
				font-size: 16px;
				font-family: 'Centra Book';
				color: rgba(0, 0, 0, 0.35);
			}
		}

		.creditCard-icon {
			float: left;
			margin-bottom: 10px;
		}
	}
	.continue-btn {
		margin-top: 15px;
		float: right;
	}
}

@media screen and (max-width: 1200px) {
	.fields {
		display: flex;
		flex-direction: column;

		.row {
			display: flex;
			justify-content: space-between;
			gap: 10px;

			& > div {
				width: 50%;
			}
		}
	}
}
