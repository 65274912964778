.checkout-response {
  // background-color: white;

  .img-transaction-response {
    margin-bottom: 25px;
  }

  .container-ticket {
    font-size: 16px;
    padding: 10px;
    overflow: auto;
    height: 100%;
    max-height: calc(100vh - 70px);
    background-color: white;
    border-radius: 10px 10px 0 0;
    
    #errorMessageTransaction {
      border-bottom: solid rgb(241, 241, 241) 1px;
    }
    .containerAuthorizacion {
      text-align: center;
      padding: 10px;
    }
    .container-transaction-info {
      display: grid;
      grid-template-columns: 40% auto;
      margin-bottom: 35px;
    }
    .text-center {
      text-align: center;
    }
    .ticket-footer {
      font-size: 10px;
    }
    .container-transaction-info .amount {
      color: inherit !important;
      font-size: 1em !important;
    }
    .container-transaction-info > div {
      padding: 15px 10px 15px 10px;
    }
    .container-transaction-info .payment-logo {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .container-transaction-info .payment-logo img {
      height: 17px;
      margin-bottom: 4px;
      margin-right: 15px;
    }
    .container-transaction-info > div:nth-child(odd) {
      border-bottom: solid rgb(232, 232, 232) 1px;
      border-top: solid rgb(232, 232, 232) 1px;
      border-right: solid rgb(232, 232, 232) 1px;
      color: rgb(144, 144, 144);
      font-weight: 200;
      text-align: right;
    }
    .container-transaction-info > div:nth-child(even) {
      border-bottom: solid rgb(232, 232, 232) 1px;
      border-top: solid rgb(232, 232, 232) 1px;
    }
    .footer-logo-beluga {
      margin-top: 15px;
    }
    .image-footer-ticket {
      position: absolute;
      bottom: -13px;
    }
    .mb-7 {
      margin-bottom: 4em;
    }
    .flex-center {
      display: flex;
      align-items: center;
    }
    .btn-options-container {
      text-align: center;
      margin: 30px 0;
    }
  }
  @media screen and (max-width: 768px) {
    .container-ticket {
      font-size: 13px;
    }
  }
}

.image-footer-ticket {
  .cls-print-ticket-1{
    isolation:isolate;
  }
  .cls-print-ticket-2{
    fill: #fff;
    fill-rule: evenodd;
    mix-blend-mode: multiply;
  }
}