.btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	width: -moz-fit-content;
	width: fit-content;
	height: 36px;
	border: 0;
	span {
		font-family: 'Centra Medium';
		font-size: 17px;
		font-weight: 500;
		padding: 10px 15px;
	}
}

.disabled {
	background-color: #8e8e93;
	color: white;
	@media screen and (max-width: 1200px) {
		&:hover {
			background-color: #8e8e93;
			color: white;
		}
	}
}

.light {
	background-color: transparent;
	border: 1.5px solid #5959ea;
	color: #5959ea;
	&:hover {
		cursor: pointer;
		border: 1.5px solid #8547ff;
		color: #8547ff;
		background-color: rgba(133, 71, 255, 0.1);
	}
	@media screen and (max-width: 1200px) {
		&:hover {
			background-color: transparent;
			border: 1.5px solid #5959ea;
			color: #5959ea;
		}
	}
}

.dark {
	background: #5959ea;
	color: white;
	&:hover {
		cursor: pointer;
		color: white;
		background: #7272ee;
	}
	@media screen and (max-width: 1200px) {
		&:hover {
			background: #5959ea;
			color: white;
		}
	}
}
