@font-face {
	font-family: 'Centra Medium';
	src: url('./fonts/CentraNo2-Medium.woff2');
}
@font-face {
	font-family: 'Centra Book';
	src: url('./fonts/CentraNo2-Book.woff2');
}
@font-face {
	font-family: 'Centra Light';
	src: url('./fonts/CentraNo2-Light.woff2');
}

body {
	margin: 0;
	font-family: 'Poppins' sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px;
	box-sizing: border-box;
}

code {
	font-family: 'Poppins' sans-serif;
}
